import React, { useEffect } from 'react'
import { Helmet } from "react-helmet"
export default function PrivacyPolicy()  {
return <>


<Helmet>
        <meta charset="UTF-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
        <title>mybee privacy policy</title>

        <link rel="stylesheet" href="/third_party/bootstrap/dist/css/bootstrap.min.css" />
        <link rel="stylesheet" href="/third_party/bootstrap/dist/css/bootstrap-theme.min.css" />

        <link rel="stylesheet" href="/stylesheets/page-top.css" />
        <link rel="stylesheet" href="/stylesheets/sticky-footer.css"/>
        <script type="text/javascript" src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
        <script type="text/javascript" src="/third_party/bootstrap/dist/js/bootstrap.min.js"></script>
        </Helmet>
        <header></header>
        <main>
        <div className="container theme-showcase">
            <div> <a href="https://www.mybee.life/"><img src="/images/logo.png" style={{width:"230px"}} className="img-responsive"/></a> </div>

            <h3><strong>Mybee Privacy Policy</strong></h3>

            <div>
                <p>Initial version: January 15, 2017</p>
            </div>

            <div>
                <p>Mybee provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of personal information we receive from users of the Mybee Services. This Privacy Policy may be updated from time to time. We will notify you of any material changes by posting the new Privacy Policy on the Site. You are advised to consult this policy regularly for any changes. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms of Service.</p>
            </div>
            <div>
                <p>As used in this policy, the terms "using" and "processing" information include using cookies on a computer, subjecting the information to statistical or other analysis and using or handling information in any way, including, but not limited to collecting, storing, evaluating, modifying, deleting, using, combining, disclosing and transferring information within our organization or among our affiliates internationally.</p>
            </div>

            <div>
                <h4><strong>Information Collection and Use</strong></h4>
                <p>Our primary goals in collecting information are to provide and improve our Services and to enable users to enjoy and easily navigate the Services.</p>

                <p><b>Personally Identifiable Information</b></p>
                <p>In the course of using the Services, you may provide us with personally identifiable information. This refers to information about you that can be used to contact or identify you ("Personal Information"). If you create an Account using your login from a Social Network Site, we will access and collect the information about you that your privacy settings on that Social Network Site permits us to access so that we can create an Account for you. We use your Personal Information mainly to provide our Services and administer your inquiries. For example, when you create an account through the Services and become a "Registered User" or when you choose to create your own profile webpage, we may ask you for Personal Information.</p>

                <p><b>Registered Users</b></p>
                <p>Once you become a Registered User, you will be able to create a profile page by submitting certain Personal Information. The profile page will display your "screen name" as well as your profile picture, if you choose to upload one. You can choose what other Personal Information you provide as part of your profile. Providing such information is voluntary and should correlate with the degree of interaction you want to have with other Mybee users. We will also collect the contact information of your friends, if you choose to connect your contacts and address book information with your Account, so that you can connect with your friends when you use our Services.</p>

                <p><b>Non-Identifying Identifiable Information</b></p>
                <p>We also collect the other information that you provide as part of registration and the administration and personalization of your profile page (e.g., without limitation, zip code (on its own) and individual preferences, such as viewing and/or food preferences) ("Non-Identifying Information"). Certain Non-Identifying Information would be considered a part of your Personal Information if it were combined with other identifiers in a way that enables you to be identified. But the same pieces of information are considered Non-Identifying Information when they are taken alone or combined only with other non-identifying information (for example, your viewing preferences).</p>

                <p>We may combine your Personal Information with Non-Identifying Information and aggregate it with information collected from other users to attempt to provide you with a better experience, to improve the quality and value of the Services and to analyze and understand how our Site and Services are used. We may also use the combined information without aggregating it to serve you specifically, for instance to deliver information to you according to your preferences or restrictions.</p>

                <p><b>Application Usage Information</b></p>
                <p>When you use the Application, our servers will automatically record information that your mobile device sends or transmits, including, but not limited to, the model and device ID number of your mobile device, user settings, location (if you enable this feature) and information about your use of the Application ("Application Usage Information"). We only store, track and monitor general usage and not information specific to any user. We use this information to provide and improve the Services, develop new products, and offer products and services that may be of interest to you.</p>

                <p><b>Cookies</b></p>
                <p>Like many websites, we use "cookies" to collect Log Data and other information. A cookie is a small data file that your browser places on your computer that helps us understand how you interact with the Site and use our Services. We use two types of cookies. We use persistent cookies to save information like your search preferences, your Account settings and login information, so that we can remember you and your settings when you return to the Site. We use session ID cookies to enable certain features of the Site or our Services, but unlike persistent cookies, session cookies are deleted when you leave or log off from the Site and/or when you close your browser. Third-party advertisers on the Site may also place or read cookies on your browser. Most Internet browsers automatically accept cookies but you can instruct your browser, by changing its options, to stop accepting cookies or to prompt you before accepting a cookie from the websites you visit. If you do not accept cookies, however, you may not be able to use all portions of the Site or all functionality of the Services.</p>

                <p>We may also use third parties to collect usage analytics for our Site and the Services we offer. These third parties will place their own cookies to collect traffic and activity data in order to deliver us relevant metrics and information. We do not share any personal information of our users with these parties, but we may share anonymized or aggregated information with them to help improve our Site and the Services we offer. The collection of this data by these third parties is subject to their own privacy policy. </p>
        
            </div>

            <div>
                <h4><strong>Information Shared with Others</strong></h4>
                <p>We do not share your Personal Information we have collected from you, except as described in this Privacy Policy or when you share something through our Service or you give us your consent to do so, with your Social Network Sites (if you allow such sharing), or with your friends with whom you want to connect (according to the preferences you have set in your Account).</p>

                <p><b>Aggregate Information</b></p>
                <p>We may share aggregated information that does not include Personal Information and we may otherwise disclose Non-Identifying Information, Application Usage Information and Log Data with third parties (including suppliers, advertisers, research companies and other organizations) for industry analysis, demographic profiling and other purposes. Any aggregated information shared in these contexts will not contain your Personal Information.</p>

                <p><b>Service Providers</b></p>
                <p>We may employ third party companies and individuals to facilitate our Services, to provide the Services on our behalf, to perform Site-related services (e.g., without limitation, maintenance services, database management, web analytics and improvement of the Site's features) or to assist us in analyzing how our Site and Services are used. These third parties have access to your Personal Information only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            
                <p><b>Compliance with Laws and Law Enforcement</b></p>
                <p>Mybee cooperates with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (including but not limited to subpoenas), to protect the property and rights of Mybee or a third party, to protect the safety of the public or any person, or to prevent or stop activity we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable activity.</p>

                <p><b>Business Transfers</b></p>
                <p>Mybee may sell, transfer or otherwise share some or all of its assets, including your Personal Information, in connection with a merger, acquisition, reorganization or sale of assets or in the event of bankruptcy.</p>
            </div>

            <div>
                <h4><strong>Changing or Deleting Your Information</strong></h4>
                <p>All registered users may review, update, correct or delete their Personal Information by contacting us or by making the appropriate modifications in your user account preferences through the Site and/or Application. If you completely delete all such information, then your account may become deactivated. If you would like us to delete your record in our system, please contact us and we will attempt to accommodate your request, however, we may have to retain a copy of some of your information for record keeping purposes.</p>
            </div>

            <div>
                <h4><strong>Security</strong></h4>
                <p>Mybee is very concerned with safeguarding your information. We employ administrative, physical and electronic measures designed to protect your information from unauthorized access, however, despite these efforts, no security measures are perfect or impenetrable and no method of data transmission can be guaranteed against any interception or other type of misuse. We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored "personal data" (as defined in applicable state statutes on security breach notification) to you via email or conspicuous posting on this Site in the most expedient time possible and without unreasonable delay, insofar as consistent with (i) the legitimate needs of law enforcement or (ii) any measures necessary to determine the scope of the breach and restore the reasonable integrity of the data system.</p>
            </div>

            <div>
                <h4><strong>International Transfer</strong></h4>
                <p>Your information may be transferred to and maintained on computers located outside of your state, province, country or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
            </div>

            <div>
                <h4><strong>Links to Other Sites</strong></h4>
                <p>Our Site contains links to other websites. If you choose to visit an advertiser by "clicking on" a banner ad or other type of advertisement, or click on another third party link, you will be directed to that third party's website. The fact that we link to a website or present a banner ad or other type of advertisement is not an endorsement, authorization or representation of our affiliation with that third party, nor is it an endorsement of their privacy or information security policies or practices. We do not exercise control over third party websites. These other websites may place their own cookies or other files on your computer, collect data or solicit personal information from you. Other sites follow different rules regarding the use or disclosure of the personal information you submit to them. We encourage you to read the privacy policies or statements of the other websites you visit.</p>
            </div>

            <div>
                <h4><strong>Our Policy Toward Children</strong></h4>
                <p>This Site is not directed to children and we do not knowingly collect personally identifiable information from children under 13. If we become aware that we have collected Personal Information from a child under 13, we will take steps to delete such information from our files as soon as possible.</p>
            </div>

            <br/><br/><br/>
        </div>

        <p id="page-top"><a href="#">TOP</a></p>
        
        <footer className="footer">
            <div className="container">
                <p class="text-muted">&copy;2017
                    <a href="http://www.mybeeai.com">
                        <font color="ffb341">mybee AI., JSC</font>
                    </a>
                    <a class="text-muted" href="/privacy_policy.html">Privacy</a>
                    <a class="text-muted" href="/term_of_service.html">Terms</a>
                </p>
            </div>
        </footer>
        </main>
</>
}